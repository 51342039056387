body,html,#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#root{
  background: #f5f5f5;
}

* {
  margin: 0;
  font-family: "oswald","roboto",sans-serif;
  font-weight: 200;
  box-sizing: border-box;
}

